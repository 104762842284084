

<body>
     <div id="printArea">
        <h1>Payment Search Results</h1>                     
		<div style="border: 1px solid black; padding: 5px; margin-bottom: 15px;">
			<p style="color: red; font-weight: bold; text-align: center;">If you are a health care provider that does business with the State of Arizona and have a question about a payment from the Arizona Health Care Cost Containment System (AHCCCS), please send those inquiries to <a href="mailto:AHCCCSWarrantInquiries@azahcccs.gov" style="color: Red;">AHCCCSWarrantInquiries@azahcccs.gov</a>.</p>
		</div>
        <div id="headerControlDate">Last Updated: <strong>{{getLastUpdateDate()}}</strong></div>
        <div><a class="ext" target="_blank" href="https://gao.az.gov/contact-us">Vendor Payment Search Agency Contacts</a></div>
        <div><a class="ext" href="#" (click)="returnToSearch()">Return to Payment Search</a></div>
        
        <div id="divMessages" class="messages" [hidden]="! isRecLimitReached">
            Maximum number of result records (5,000) reached. Consider narrowing your search criteria.<br>
        </div>
        <br />
        <div id="divResults">
        <input type="button" name="buttonDownloadToExcel" value="Download to Excel" id="buttonDownloadToExcel"  (click)="exportToExcel()" />
        <input id="buttonPrint" type="button" value="Print" (click)="print()"/>
        <p>
        <p>
            <a> Column help<img src="assets/img/help.gif" alt="Help" (mouseover)="showHelpModal('mainHelp')"  (click)="showHelpModal('mainHelp')"></a>
        <p>
            <div id="GoogleChartTable"  [hidden]="isNoResultFound">
                
                <google-chart #chart
                    [type]="chartParams.type"
                    [data]="chartParams.data" 
                    [columns]="chartParams.chartColumns" 
                    [width]="chartParams.width"
                    [height]="chartParams.height"
                    (ready)="onChartReady(chart)" >
                </google-chart>
            
            
            </div>
            <div [hidden]="! isNoResultFound">
               <a class="ext" href="#" (click)="returnToSearch()"> No results found. Return to Payment Search page to change search criteria.</a>
            </div>

        </div>
    </div>
    
</body> 
