

export class SearchParams {

  constructor() {

    this.initSearchDates();
  }

  //Search Form fields
  taxIdEinSsn : string = "";
  selectedAgency : string = "";

  dateSelectMode : string = "";  // Mode: on, between or after
  dateStart_mo : string = "";
  dateStart_day : string = "";
  dateStart_yr : string = "";
  dateStart : string = "";
 
  dateEnd_mo : string = "";
  dateEnd_day : string = "";
  dateEnd_yr : string = "";
  dateEnd : string = "";
 
  dateOn : string = "";

  amtSelectMode : string = "";   // Mode: equals, less than or greater than
  amtLowLimit : string = "";
  amtHighLimit : string = "";
  amtSingleValue : string = "";
  invoiceNum : string = "";
  warrantNum : string = "";
  city : string = "";




  
  initSearchDates() {

    var currDate : Date = new Date();
 
    this.dateStart_mo = (currDate.getMonth()+1).toString();
    this.dateEnd_mo = (currDate.getMonth()+1).toString();

    this.dateStart_day = (currDate.getDate()).toString();
    this.dateEnd_day = (currDate.getDate()).toString();

    this.dateStart_yr = (currDate.getFullYear()).toString();
    this.dateEnd_yr = (currDate.getFullYear()).toString();
  }

}
