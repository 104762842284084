<body
  style="background-color:#006699;margin:0px 0px 0px 0px;width:100%;background-image:url(http://gao.az.gov/assets/img/bg_header.gif); background-repeat: repeat-y;">

  <table border="0" cellspacing="0" cellpadding="0" width="100%" id="Logo Table">
    <tbody>
      <tr>
        <td style="width:27%;height:91px;"><span style="font-family:Arial,Helvetica,sans-serif;font-size:small;"><a
              href="http://www.azdoa.gov">
              &nbsp;</a><img src="assets/img/adoalogo.gif"></span></td>
        <td style="width:70%;height:91px;">&nbsp;</td>
        <td style="width:3%;height:91px;">
          <div style="text-align:right;">
            <img src="assets/img/gao_logo_large.gif">&nbsp;
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <table style="height:16px;margin:0px;padding:0px;" width="100%" border="0" cellspacing="0" cellpadding="0"
    id="Date / Time Header Table">
    <tbody>
      <tr style="background-color:#FFCC66;">
        <td style="width:2%;">
          <div style="text-align:left;"><img src="assets/img/icon_dots.gif">&nbsp;</div>
        </td>
        <td style="width:97%;">
          <div style="text-align:center;font-family:Verdana,Arial,Helvetica,sans-serif;font-size:small;color:#000000;">
            Current Date: {{getCurrentDate()}} &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Current time: {{getCurrentTime()}}
          </div>
        </td>
        <td style="width:1%;">
          <div style="text-align:right;"><img src="assets/img/icon_dots.gif">&nbsp;</div>
        </td>
      </tr>
    </tbody>
  </table>
  <table style="height:331px;" border="1" cellspacing="2" cellpadding="1" width="100%" id="Main Content table">
    <tbody>
      <tr>

        <td style="background-color:#FFFFFF;height:38px;width:98%;" valign="top">
          <form name="aspnetForm" method="post" action="default.aspx" id="aspnetForm">
            <div>
            </div>



            <div>

              <table width="100%">
                <tbody>
                  <tr style="height:100%;">
                    <!--<td style="width:1%">&nbsp;</td>-->
                    <td style="background-color:#e3ddeb;padding:12px;">

                      <div id="ctl00_contentPlaceHolder_divSearchForm" style="text-align:center;">

                        <h1>
                          <img src="assets/img/azflag_state_mask.gif" alt="Flag">
                          <img src="assets/img/pay_search.gif" alt="Search">
                        </h1>
                        <!-- ALERT-->
                        <div style="border: 1px solid rgb(17, 17, 17); padding: 5px;">
                          <p style="color: red; ; text-align: left;"><img src="assets/img/achtung.png" width="45" />
                            This website was designed as a convenient tool to
                            quickly look up payment information. It was not designed as a payment database for audit
                            purposes,
                            ongoing payment reconciliation tool and payment schedule inquiry. This website only contains
                            payments issued from one (1) previous calendar year plus payments from the current calendar
                            year.<br><br>
                            If you are constantly using this website to identify and reconcile payments received from
                            the State of Arizona, you are advised to contact the State
                            agency/department directly that is issuing payments and request the agency to include proper
                            remittance information with the payments going forward. <br><br>
                            To identify the agency that issues payments to you, look for the three (3) agency/department
                            alphabetic letters at the top-left corner of the remittance
                            advice section of the warrant (check), or in the last ACH addenda record (record 7) if the
                            payment is an ACH/EFT payment in the CTX format. To obtain
                            the agency name and contact information for payment information inquiries based on the three
                            (3) agency/department alphabetic codes, use the <a target="_blank"
                              href="https://gao.az.gov/contact-us">Vendor Payment Search Agency Contacts</a>.
                            <br><br>
                            If you receive health care related payments from the Arizona Health Care Cost Containment
                            System (AHCCCS) and have questions about these payments,
                            contact <font color="blue"><u>AHCCCSWarrantInquiries@azahcccs.gov</u></font>, or
                            <b>(602) 417-5500</b> as AHCCCS is the only agency that can provide payment information.
                            The three (3) agency/department alphabetic letters for these payments are shown as “HCA” on
                            the top
                            of the warrant (check) remittance advice. <br><br>
                            The information contained on this website is for information purposes only and it should not
                            be considered as official records. The information
                            contained on this website must be accessed with a known Taxpayer Identification Number (TIN)
                            for security purposes. This website only contains
                            payment information originating from the State’s Official Accounting System, Arizona
                            Financial Information System (AFIS). Payments made by some
                            Arizona State agencies such as Arizona Department of Revenue for tax refund payments,
                            Arizona Department of Economic Security for social service
                            payments, and payments from agencies’ other systems that are not integrated to AFIS will not
                            be available on this website.

                        </div>

                        <!--END ALERT-->
                      <p>
                     
                          <font color="red">Search results will contain payments from <i>one</i> (1) previous calendar year
                            plus payments from the
                            current calendar year.</font>
                        



                        <div id="validationErrors" class="messages" [hidden]="! isValErrorsExist">
                          <!-- ERROR MESSAGES-->
                          <li *ngFor="let error of valErrors;">
                            <b> {{error.defaultMessage}} </b>
                          </li>
                        </div>
                        <hr>

                        <br>
                        <table style="margin-left: auto;margin-right: auto;">
                          <tbody>

                            <tr>
                              <td width="50%" align="right"><strong>Taxpayer ID # (EIN or SSN)</strong></td>
                              <td width="25%" align="left">
                                <input class="form-control" name="taxIdEinSsn" type="text" id="taxIdEinSsn"
                                  maxlength="9" style="width: 135px;" (blur)="checkTaxpayerID();"
                                  [(ngModel)]="searchParams.taxIdEinSsn" required>
                                <strong style="color:red;">&nbsp;&nbsp;REQUIRED FIELD</strong>
                              </td>
                              <td width="25%" align="left"><a><img src="assets/img/help.gif" alt="Help"
                                    (click)="showHelpModal('taxIdHelp')"></a>
                              </td>
                            </tr>
                            <tr>
                              <td width="50%" align="right">Agency</td>
                              <td width="25%" align="left">

                                <select class="form-control" style="width:50%" name="agencyCode"
                                  [(ngModel)]="searchParams.selectedAgency">
                                  <option *ngFor="let agency of agencies" [value]="agency.agencyCode">
                                    {{agency.agencyCode}} {{agency.agencyCode==""? "":"-"}} {{agency.agencyName}}
                                  </option>
                                </select>



                              </td>
                              <td width="25%" align="left"><a><img src="assets/img/help.gif" alt="Help"
                                    (click)="showHelpModal('agencyHelp')"></a></td>

                            </tr>
                          </tbody>
                        </table>
                        <h3>
                          <img src="assets/img/pay_date.gif" alt="Date">&nbsp;
                        </h3>

                        <!--<table payment date>-->
                        <table id="ctl00_contentPlaceHolder_tablePaymentDate" style="text-align:center;">

                          <tbody>
                            <tr id="ctl00_contentPlaceHolder_rowPaymentDateFrom">
                              <td width="50%" align="right">
                                Date
                                <select name="dateSelectMode" (change)="setPaymentToDateStyle()" id="dateSelectMode"
                                  [(ngModel)]="searchParams.dateSelectMode">
                                  <option selected="selected" value="">&lt;Select ...&gt;</option>
                                  <option value="between">Between</option>
                                  <option value="on">On</option>
                                  <option value="after">After</option>
                                </select>
                              </td>
                              <td width="25%" align="left">
                                <select name="dateStart_mo" (change)="dummy()" id="dateStart_mo"
                                  [(ngModel)]="searchParams.dateStart_mo">
                                  <option value="1">Jan</option>
                                  <option value="2">Feb</option>
                                  <option value="3">Mar</option>
                                  <option value="4">Apr</option>
                                  <option selected="selected" value="5">May</option>
                                  <option value="6">Jun</option>
                                  <option value="7">Jul</option>
                                  <option value="8">Aug</option>
                                  <option value="9">Sep</option>
                                  <option value="10">Oct</option>
                                  <option value="11">Nov</option>
                                  <option value="12">Dec</option>

                                </select>

                                <select name="dateStart_day" (change)="dummy" id="dateStart_day"
                                  [(ngModel)]="searchParams.dateStart_day">
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option selected="selected" value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                  <option value="21">21</option>
                                  <option value="22">22</option>
                                  <option value="23">23</option>
                                  <option value="24">24</option>
                                  <option value="25">25</option>
                                  <option value="26">26</option>
                                  <option value="27">27</option>
                                  <option value="28">28</option>
                                  <option value="29">29</option>
                                  <option value="30">30</option>
                                  <option value="31">31</option>

                                </select>

                                <select name="dateStart_yr" (change)="dummy()" id="dateStart_yr"
                                  [(ngModel)]="searchParams.dateStart_yr">
                                  <option value="2001">2001</option>
                                  <option value="2002">2002</option>
                                  <option value="2003">2003</option>
                                  <option value="2004">2004</option>
                                  <option value="2005">2005</option>
                                  <option value="2006">2006</option>
                                  <option value="2007">2007</option>
                                  <option value="2008">2008</option>
                                  <option value="2009">2009</option>
                                  <option value="2010">2010</option>
                                  <option value="2011">2011</option>
                                  <option value="2012">2012</option>
                                  <option value="2013">2013</option>
                                  <option value="2014">2014</option>
                                  <option value="2015">2015</option>
                                  <option value="2016">2016</option>
                                  <option value="2017">2017</option>
                                  <option value="2018">2018</option>
                                  <option value="2019">2019</option>
                                  <option value="2020">2020</option>
                                  <option selected="selected" value="2021">2021</option>
                                  <option value="2022">2022</option>
                                  <option value="2023">2023</option>
                                  <option value="2024">2024</option>
                                  <option value="2025">2025</option>

                                </select>

                                <input type="hidden" name="dateStart" id="dateStart" value=""
                                  [(ngModel)]="searchParams.dateStart">
                                <script type="text/javascript" language="javascript">
                                </script>


                                <input style="width:0px !important" matInput [matDatepicker]="dpStart"
                                  [(ngModel)]="dpStartDate" name="dpStartDate" (dateChange)="setStartDateFromDP()"
                                  disabled>
                                <!--style="display:none"-->
                                <mat-datepicker-toggle matSuffix [for]="dpStart"></mat-datepicker-toggle>
                                <mat-datepicker #dpStart disabled="false"></mat-datepicker>

                              </td>
                              <td width="25%" align="left"><a><img src="assets/img/help.gif" alt="Help"
                                    (click)="showHelpModal('dateHelp')"></a>
                              </td>
                            </tr>

                            <tr id="rowPaymentToDate">
                              <td align="right">and</td>
                              <td align="left">
                                <select name="dateEnd_mo" (change)="dummy()" id="dateEnd_mo"
                                  [(ngModel)]="searchParams.dateEnd_mo">
                                  <option value="1">Jan</option>
                                  <option value="2">Feb</option>
                                  <option value="3">Mar</option>
                                  <option value="4">Apr</option>
                                  <option selected="selected" value="5">May</option>
                                  <option value="6">Jun</option>
                                  <option value="7">Jul</option>
                                  <option value="8">Aug</option>
                                  <option value="9">Sep</option>
                                  <option value="10">Oct</option>
                                  <option value="11">Nov</option>
                                  <option value="12">Dec</option>

                                </select>

                                <select name="dateEnd_day" onchange="dummy()" id="dateEnd_day"
                                  [(ngModel)]="searchParams.dateEnd_day">
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                  <option value="6">6</option>
                                  <option value="7">7</option>
                                  <option selected="selected" value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                  <option value="21">21</option>
                                  <option value="22">22</option>
                                  <option value="23">23</option>
                                  <option value="24">24</option>
                                  <option value="25">25</option>
                                  <option value="26">26</option>
                                  <option value="27">27</option>
                                  <option value="28">28</option>
                                  <option value="29">29</option>
                                  <option value="30">30</option>
                                  <option value="31">31</option>

                                </select>

                                <select name="dateEnd_yr" (change)="dummy()" id="dateEnd_yr"
                                  [(ngModel)]="searchParams.dateEnd_yr">
                                  <option value="2001">2001</option>
                                  <option value="2002">2002</option>
                                  <option value="2003">2003</option>
                                  <option value="2004">2004</option>
                                  <option value="2005">2005</option>
                                  <option value="2006">2006</option>
                                  <option value="2007">2007</option>
                                  <option value="2008">2008</option>
                                  <option value="2009">2009</option>
                                  <option value="2010">2010</option>
                                  <option value="2011">2011</option>
                                  <option value="2012">2012</option>
                                  <option value="2013">2013</option>
                                  <option value="2014">2014</option>
                                  <option value="2015">2015</option>
                                  <option value="2016">2016</option>
                                  <option value="2017">2017</option>
                                  <option value="2018">2018</option>
                                  <option value="2019">2019</option>
                                  <option value="2020">2020</option>
                                  <option selected="selected" value="2021">2021</option>
                                  <option value="2022">2022</option>
                                  <option value="2023">2023</option>
                                  <option value="2024">2024</option>
                                  <option value="2025">2025</option>

                                </select>

                                <input type="hidden" name="ctl00$contentPlaceHolder$hiddenPaymentDateTo"
                                  id="ctl00_contentPlaceHolder_hiddenPaymentDateTo" value="5/8/2021">
                                <script type="text/javascript" language="javascript">

                                </script>


                                <input style="width:0px !important" matInput [matDatepicker]="dpEnd"
                                  (dateChange)="setEndDateFromDP()" [(ngModel)]="dpEndDate" name="dpEndDate" disabled>
                                <!--style="display:none"-->
                                <mat-datepicker-toggle matSuffix [for]="dpEnd"></mat-datepicker-toggle>
                                <mat-datepicker #dpEnd disabled="false"></mat-datepicker>



                              </td>
                              <td>&nbsp;</td>
                            </tr>


                          </tbody>
                        </table>

                        <h3><img src="assets/img/pay_details.gif" alt="Details">&nbsp;</h3>
                        <div id='spinarea'></div>
                        <table id="ctl00_contentPlaceHolder_tableTransactionAmount" style="margin-left: auto;margin-right: auto;">
                          <tbody>
                            <tr id="ctl00_contentPlaceHolder_rowPaymentFrom">
                              <td width="50%" align="right">
                                Amount
                                <select name="amtSelectMode" (change)="setPaymentToAmountStyle()" id="amtSelectMode"
                                  [(ngModel)]="searchParams.amtSelectMode">
                                  <option selected="selected" value="">&lt;Select ...&gt;</option>
                                  <option value="between">Between</option>
                                  <option value="equalto">= Equal To</option>
                                  <option value="greaterthan">&gt; Greater Than</option>
                                  <option value="lessthan">&lt; Less Than</option>

                                </select>
                              </td>


                              <td width="25%" align="left">
                                <input name="amtLowLimit" type="text" id="amtLowLimit"
                                  [(ngModel)]="searchParams.amtLowLimit">
                              </td>
                              <td width="25%" align="left"><a><img src="assets/img/help.gif" alt="Help"
                                    (click)="showHelpModal('amountHelp')"></a></td>
                            </tr>
                            <tr id="rowPaymentToAmount">
                              <td align="right">and</td>
                              <td align="left">
                                <input name="amtHighLimit" type="text" id="amtHighLimit"
                                  [(ngModel)]="searchParams.amtHighLimit">
                              </td>
                              <td>&nbsp;</td>
                            </tr>
                            <tr>
                              <td align="right">Invoice Number</td>
                              <td align="left">
                                <input name="invoiceNum" type="text" maxlength="14" id="invoiceNum" style="width:210px;"
                                  [(ngModel)]="searchParams.invoiceNum">
                              </td>
                              <td align="left"><a><img src="assets/img/help.gif" alt="Help"
                                    (click)="showHelpModal('invoiceNumHelp')"></a></td>
                            </tr>
                            <tr>
                              <td align="right">Warrant/Payment Number</td>
                              <td align="left">
                                <input name="warrantNum" type="text" maxlength="9" id="warrantNum"
                                  [(ngModel)]="searchParams.warrantNum" style="width:135px;">
                              </td>
                              <td align="left"><a><img src="assets/img/help.gif" alt="Help"
                                    (click)="showHelpModal('warrantNumHelp')"></a></td>
                            </tr>
                            <tr>
                              <td align="right">City</td>
                              <td align="left">
                                <input name="city" type="text" id="city" [(ngModel)]="searchParams.city"
                                  style="width:210px;">
                              </td>
                              <td align="left"><a><img src="assets/img/help.gif" alt="Help"
                                    (click)="showHelpModal('cityHelp')"></a>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <br>

                        <br>
                        <img name="buttonSubmit" id="buttonSubmit" src="assets/img/submit_button.gif"
                          style="border-width:0px; width:'100'" (click)="searchPayment()">
                        <img name="buttonReset" id="buttonReset" src="assets/img/reset_button.gif"
                          style="border-width:0px;width:'100'" (click)="resetForm()">
                        <img name="buttonHelp" id="buttonHelp" src="assets/img/help_button.gif"
                          style="border-width:0px;width:'100'" (click)="showHelpModal('helpButtonHelp')" alt="Help"
                          value="Help">
                      </div>
                      <br>
                      <hr>
                      <p style="text-align:center;">
                        <font color="blue">For technical difficulty inquiries only </font>
                        <font color="red">(NOT FOR SPECIFIC
                          PAYMENT INFORMATION INQUIRIES)</font>, <font color="blue">please send an email to </font>
                        <font color="blue"><u>vendors@azdoa.gov</u></font>.
                      </p>
                    </td>
                    <!--<td style="width:1%">&nbsp;</td>-->
                  </tr>
                </tbody>
              </table>


            </div>
          </form>
        </td>
      </tr>
    </tbody>
  </table>
  <table style="height:16px;margin:0px;padding:0px;" width="100%" border="0" cellspacing="0" cellpadding="0"
    id="Bottom Navigation table">
    <tbody>
      <tr style="background-color:#FFCC66;">
        <td style="width:2%; height: 16px;">
          <div style="text-align:left;"><img src="http://gao.az.gov/assets/img/icon_dots.gif" width="16" height="16"
              alt="Image of four dots"></div>
        </td>
        <td style="width:97%; height: 16px;">
          <div style="text-align:center;font-family:Verdana;color:#FFFFFF;font-size:xx-small;">
            <a class="blackLink" href="http://www.azdoa.gov/privacy-policy" target="_blank">Privacy Statement</a>
            <div style="display:inline;font-size:xx-small;color:#FFFFFF;"> | </div>
            <a class="blackLink"
              href="http://www.azgita.gov/policies_standards/html/p130_web_site_accessibility_policy.htm"
              target="_blank">Accessibility</a>
            <div style="display:inline;font-size:xx-small;color:#FFFFFF;"> |&nbsp;</div>
            <!--<a id="ctl00_HyperLink1" class="blackLink" href="https://gao.az.gov/contact-us">Contact GAO</a> -->
          </div>
        </td>
        <td style="width:1%; height: 16px;">
          <div style="text-align:right;background-color:#FFCC66;"><img src="http://gao.az.gov/assets/img/icon_dots.gif"
              width="16" height="16" alt="Image of four dots"></div>
        </td>
      </tr>
    </tbody>
  </table>
  <table style="text-align:center;" width="100%" border="0" cellspacing="0" cellpadding="0"
    id="Copyright information table">
    <tbody>
      <tr>
        <td>
          <div style="text-align:center;">
            <span style="font-family:Arial,Helvetica,sans-serif;font-size:x-small;color:#FFFFFF;">
              Copyright 2001 Arizona Department of Administration, General Accounting Office - All Rights Reserved
              <br>
              100 North 15th Avenue, Suite 302 - Phoenix, AZ 85007
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>


</body>