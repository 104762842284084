import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {PaymentsearchComponent} from 'src/app/venpay/paymentsearch/paymentsearch.component';
import {SearchresultComponent } from 'src/app/venpay/searchresult/searchresult.component';


const routes: Routes = [

  { path: '',redirectTo:'paymtsearch',pathMatch:'full'},
  { path: 'paymtsearch', component: PaymentsearchComponent },
  { path: 'searchresult', component: SearchresultComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
