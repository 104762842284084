import { Component, OnInit,Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-popupcolheaderdesc',
  templateUrl: './popupcolheaderdesc.component.html',
  styleUrls: ['./popupcolheaderdesc.component.css']
})
export class PopupcolheaderdescComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: string,
    public dialogRef: MatDialogRef<PopupcolheaderdescComponent>) { }

  colDesc : string;
  
  ngOnInit() {
    this.colDesc = this.data;
  }

  closeModal() {
    this.dialogRef.close();
  }

}
