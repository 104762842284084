import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { PopupcolheaderdescComponent } from '../popupcolheaderdesc/popupcolheaderdesc.component';


@Component({
  selector: 'app-popupcolheaders',
  templateUrl: './popupcolheaders.component.html',
  styleUrls: ['./popupcolheaders.component.css']
})
export class PopupcolheadersComponent implements OnInit {


  constructor(
              public dialogRef: MatDialogRef<PopupcolheadersComponent>,
              public dialog: MatDialog
             ) { }

  ngOnInit() {
    console.log('Dialog was opened');
  }

   helpDialogRef : MatDialogRef<PopupcolheaderdescComponent>;


  helpTexts: {[key: string]: string} =
   {
    "TaxpayerId": "<b>TaxpayerId</b><p>The Taxpayer Identification Number (TIN) is a 9-digit Social Security Number \"SSN\" issued by the Social Security Administration (SSA) or an Employer Identification Number \"EIN\" issued by the Internal Revenue Service (IRS).",
    "AzAgencyId": "<b>AzAgencyId</b><p>The 3-letter code used in the Arizona Financial Information System (AFIS) to represent a Department, Authority, Board, Commission, Council, Administration, Court Registrar, Office, Institution, or other Arizona entity in the Executive, Legislative, or Judicial Branch.",
    "AzAgencyName": "<b>AzAgencyName</b><p>The title of the Department, Authority, Board, Commission, Council, Administration, Court Registrar, Office, Institution, or other Arizona entity in the Executive, Legislative, or Judicial Branch responsible for making payment.",
    "MailCode": "<b>MailCode</b><p>The 5-digit code assigned by the State of Arizona's General Accounting Office (GAO) to represent an address for the vendor.",
    "City": "<b>City</b><p>If a warrant was issued (paper payment), the city where payment was sent to. If an ACH (Automated Clearing House) payment (electronic payment) was issued, the city on file for the vendor.",
    "State": "<b>State</b><p>If a warrant was issued (paper payment), the state where payment was sent to. If an ACH (Automated Clearing House) payment (electronic payment) was issued, the state on file for the vendor.",
    "Zip": "<b>Zip</b><p>If a warrant was issued (paper payment), the zip code where payment was sent to. If an ACH (Automated Clearing House) payment (electronic payment) was issued, the zip code on file for the vendor.",
    "InvoiceNo": "<b>InvoiceNo</b><p>The number assigned by the vendor to represent the bill.",
    "InvoiceDesc": "<b>InvoiceDesc</b><p>Up to 30 characters of additional information corresponding to the payment transaction that the paying state agency entered during data entry into the Arizona Financial Information System.",
    "InvoiceDate": "<b>InvoiceDate</b><p>The date assigned by the vendor for the invoice.",
    "TransactionAmt": "<b>TransactionAmt</b><p>The amount corresponding to a specific transaction. Several transaction amounts may need to be combined to equal the total payment amount of the warrant or ACH payment.",
    "PaymentAmt": "<b>PaymentAmt</b><p>The total amount of the corresponding warrant (paper payment) or ACH (Automated Clearing House) payment (electronic payment).",
	"PaymentType": "<b>PaymentType</b><p><ul><li><b>A</b> = ACH (Automated Clearing House) payment (electronic payment)</li><li><b>W</b> = State of Arizona warrant (paper payment)</li></ul>",    
    "PaymentNo": "<b>PaymentNo</b><p>A 9-digit number assigned by the State of Arizona's General Accounting Office to represent the payment in the Arizona Financial Information System. For security reasons, only the last 5 digits of the payment are displayed.",
    "IssueDate": "<b>IssueDate</b><p>The date payment was made.",
    "PaymentStatus": "<b>PaymentStatus</b><p>The status of the payment:<ul><li>1=Disbursed</li><li>2=Warranted</li><li>3=Paid</li><li>4=Cancelled</li><li>5=Stale Dated</li><li>6=Void</li><li>7=Escheat</li><li>8=Renumbered</li><li>20=Wire Transfer</li></ul>",
    "StatusDate": "<b>StatusDate</b><p>The date corresponding to the payment status.",
    "1099MiscBoxNo": "<b>1099MiscBoxNo</b><p>The box number where payments may be reported on a 1099-MISC form for tax purposes. If blank, payment does not meet the requirements for reporting on a 1099-MISC. Additional criteria used to determine if payments are reportable or not on a 1099-MISC include:<br/><br/>Payments for which a 1099-MISC is required:<ul><li>At least $10 in royalties or broker payments in lieu of dividends or tax-exempt interest;</li><li>At least $600 in rents, services (including parts and materials), prizes and awards, other income payments, medical and health care payments, crop insurance proceeds, cash payments for fish (or other aquatic life) you purchase from anyone engaged in the trade or business of catching fish, or generally, the cash paid from a notional principal contract to an individual, partnership, or estate;</li><li>Any fishing boat proceeds; or</li><li>Gross proceeds to an attorney.</li></ul>Payments for which a 1099-MISC is not required:<ul><li>Generally, payments to a corporation;</li><li>Payments for merchandise, telegrams, telephone, freight, storage, and similar items;</li><li>Payments of rent to real estate agents;</li><li>Wages paid to employees;</li><li>Business travel allowances paid to employees;</li><li>Cost of current life insurance;</li><li>Payments to a tax-exempt organization, the United States, a state, the District of Columbia, a U.S. possession, or a foreign government;</li><li>Certain payment card transactions if a payment card organization has assigned a merchant/payee a Merchant Category Code (MCC) indicating that reporting is not required.</li></ul>See IRS Instructions for Form 1099-MISC and General Instructions for Forms 1099, 1098, 5498, and W-2 at www.irs.gov for additional reporting information." 
   }
 
  showHelpDetail(colname : string) {

        var helpBoxWidth:string='500px'; 
        if(colname=='1099MiscBoxNo') {
          helpBoxWidth='900';
        }  

        this.helpDialogRef = this.dialog.open(PopupcolheaderdescComponent, {


        width: helpBoxWidth,
        height: 'auto',
        data : this.helpTexts[colname],
        //panelClass : 'help-class',
        backdropClass: 'backdropBackground' 
      });
  
      this.helpDialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
     
      });
    }



    closeModal() {
      this.dialogRef.close();
    }

  }







