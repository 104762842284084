import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { PaymentSearchService } from 'src/app/venpay/paymentsearch/PaymentSearchService.service';
//import { HelpModalService } from 'src/app/venpay/paymentsearch/HelpModalService.service';
import { SearchParams } from 'src/app/venpay/paymentsearch/models/searchparams.model';
//import { PopuphelpComponent } from 'src/app/venpay/popuphelp/popuphelp.component';
import { PopupcolheadersComponent } from '../popupcolheaders/popupcolheaders.component';



declare var Spinner : any; // Spinner from spin.js




@Component({
  selector: 'app-paymentsearch',
  templateUrl: './paymentsearch.component.html',
  styleUrls: ['./paymentsearch.component.css'],
  providers: []
})
export class PaymentsearchComponent implements OnInit {

  constructor(
              private paymentSearchService : PaymentSearchService,
              private router : Router,
              public dialog: MatDialog
              ) { }

  private initFormSubscription : Subscription = new Subscription();
  private paymtSearchSubscription : Subscription = new Subscription();

  agencies : any[] = [{agencyCode:"", agencyName:"[Select...]"}];

  searchParams : SearchParams = new SearchParams();
  currentDate : Date = new Date();

  dpStartDate : Date = {} as Date;
  dpEndDate : Date = {} as Date;

  isValErrorsExist : boolean = false;
  valErrors : any[] ;

 


  public searchPayment() {

      console.log(this.searchParams);
      this.valErrors = [];
      this.isValErrorsExist = false;


      this.paymentSearchService.savedSearchParams = this.searchParams; // Save results in case of revisiting search page.

      var spinner = this.startSpinner('spinarea');
      this.paymtSearchSubscription = this.paymentSearchService.searchPaymt(this.searchParams)
      .subscribe(
           result => {
            
            // console.log(JSON.stringify(result));
             this.paymentSearchService.searchResult = result;
             console.log('RESULT:'); //TODO remove
             console.log(result);
             spinner.stop();
             this.router.navigate(['searchresult']);  

           }, err => {

              this.isValErrorsExist = true;
              console.log("Below is the error object: ");
              // console.log(err.error.errors[0].defaultMessage);
              this.valErrors = err.error.errors;
              console.log(err);

              spinner.stop();
           }
      );


  }


  ngOnInit() {

    this.isValErrorsExist = false; //clear validation errors
    this.valErrors = [];

    this.searchParams = this.paymentSearchService.savedSearchParams;
    this.setPaymentToAmountStyle()

    //Initialize form
    this.initFormSubscription = this.paymentSearchService.getAllAgencies()
    .subscribe(
         results => {
          this.agencies = this.agencies.concat(results);
         }, err => {
            console.log(err);
         }
    );
  }   
  

  checkTaxpayerID() {

  }

 dummy() {

 }

 // Visibility of 'To Date' dropdown
 setPaymentToDateStyle() {

  const row = document.getElementById("rowPaymentToDate")! ;

   if(this.searchParams.dateSelectMode == 'between' || 
      this.searchParams.dateSelectMode == ""
   ) 
   {
    row.style.display="table-row";
   } else {
    row.style.display="none";
   }
 }

 //Visibility of 'Amount upper limit' dropdown
 setPaymentToAmountStyle() {

  const row = document.getElementById("rowPaymentToAmount")! ;

   if(this.searchParams.amtSelectMode == 'between' || 
      this.searchParams.amtSelectMode == ""
   ) 
   {
    row.style.display="table-row";
   } else {
    row.style.display="none";
   }
 }
 
// Getcurrent date / time
  getCurrentDate() {
    return formatDate(new Date(), 'MM/dd/yyyy', 'en'); 
  }
  getCurrentTime() {
    return formatDate(new Date(), 'h:mm:ss a', 'en'); 
  }



  //Text for help modals and tooltips

  helpTexts: {[key: string]: string} =
  {
   "taxIdHelp" : "<span class='field'>Taxpayer ID # (EIN, or SSN):</span><span class='optional'> REQUIRED and must be an EXACT MATCH.</span><span class='desc'> A Taxpayer Identification Number (TIN) is a 9 digit Social Security Number 'SSN' issued by the Social Security Administration (SSA) or Employer Identification Number 'EIN' issued by the Internal Revenue Service (IRS).</span>",
   "agencyHelp" : "<span class='field'>Agency:</span> <span class='optional'>OPTIONAL.</span><span class='desc'>Any Department, Authority, Board, Commission, Council, Administration, Court Registrar, Office, Institution, or other Arizona entity in the Executive, Legislative, or Judicial Branch. <span>",
   "dateHelp" : "<span class='field'>Payment Date:</span>    <span class='optional'>OPTIONAL.</span>    <span class='desc'>The date representing when payment was issued,     not when payment was mailed to vendor.</span>    <span class='options'>Options to search are: Range (selecting 'Between'),     Exact match (selecting 'On'), or Minimize the results (selecting 'After').</span>         ",
   "amountHelp" : "<span class='field'>Amount:</span>    <span class='optional'>OPTIONAL.</span>    <span class='desc'>An amount corresponding to a specific transaction.     Several transaction amounts may need to be combined to equal the total     payment amount of the Warrant or ACH payment.</span>    <span class='options'>Options to search are: Range selecting     (selecting 'Between'), Exact match (selecting '= Equal to'), or     Minimize the results (selecting '> Greater Than' or selecting '< Less Than').</span>",
   "invoiceNumHelp" : "<span class='field'>Invoice Number:</span>    <span class='optional'>OPTIONAL and must be an EXACT MATCH.</span>    <span class='desc'>A number assigned by the vendor to represent a specific bill.</span></p>",
   "warrantNumHelp" : "<span class='field'>Warrant/Payment Number:</span>    <span class='optional'>OPTIONAL.</span>    <span class='desc'>A 9-digit number assigned by the State of Arizona's     General Accounting Office to represent the payment in the Arizona Financial     Information System (AFIS).</span>    <span class='options'>This field can be a partial match.     If the payment was a warrant (paper payment), the number would be on     the top right corner of the face of the warrant.    If the payment was an Automated Clearing House (ACH) payment     (electronic payment), the number may be transmitted to the vendor     (possibly represented on bank statement) and would start with the number '00'.</span>         ",
   "cityHelp" : "<span class='field'>City:</span>    <span class='optional'>OPTIONAL.</span>    <span class='desc'>If a warrant was issued (paper payment), the city     where payment was sent to. If an ACH (Automated Clearing House) payment     (electronic payment) was issued, the city on file for the vendor.</span>",
   "helpButtonHelp" : "<span class='field'>HELP BUTTON:</span> <span class='desc'>Enter a 9-digit Taxpayer ID#, read DISCLAIMER, and click SUBMIT to search for State of Arizona payments. Click on the ? buttons for more information about each field.</span>"
  }



  showHelpModal(name : string) {

    const dialogRef = this.dialog.open(PopupcolheadersComponent, {
      width: '300px',
      height: 'auto',
      data : this.helpTexts[name],
      //panelClass : 'help-class',
      backdropClass: 'backdropBackground' 
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
   
    });
  }












  //Set start/end dates from Datepicker
  setStartDateFromDP() {

    this.searchParams.dateStart_mo = (this.dpStartDate.getMonth()+1).toString();
    this.searchParams.dateStart_day = (this.dpStartDate.getDate()).toString();
    this.searchParams.dateStart_yr = (this.dpStartDate.getFullYear()).toString();
  }
  setEndDateFromDP() {
    this.searchParams.dateEnd_mo = (this.dpEndDate.getMonth()+1).toString();
    this.searchParams.dateEnd_day = (this.dpEndDate.getDate()).toString();
    this.searchParams.dateEnd_yr = (this.dpEndDate.getFullYear()).toString();
  }


  resetForm() { 
    this.searchParams = new SearchParams();

  }

  // Initialize with current (today's) date
  initSearchDates() {

    var currDate : Date = new Date();
 
    this.searchParams.dateStart_mo = (currDate.getMonth()+1).toString();
    this.searchParams.dateEnd_mo = (currDate.getMonth()+1).toString();

    this.searchParams.dateStart_day = (currDate.getDate()).toString();
    this.searchParams.dateEnd_day = (currDate.getDate()).toString();

    this.searchParams.dateStart_yr = (currDate.getFullYear()).toString();
    this.searchParams.dateEnd_yr = (currDate.getFullYear()).toString();
  }


//////////////////////Spinner ///////////////////////////

 opts = {
  lines: 13, // The number of lines to draw
  length: 9, // The length of each line
  width: 3, // The line thickness
  radius: 16, // The radius of the inner circle
  scale: 1, // Scales overall size of the spinner
  corners: 1, // Corner roundness (0..1)
  color: '#888888', // CSS color or array of colors
  fadeColor: 'transparent', // CSS color or array of colors
  speed: 1, // Rounds per second
  rotate: 0, // The rotation offset
  animation: 'spinner-line-fade-quick', // The CSS animation name for the lines
  direction: 1, // 1: clockwise, -1: counterclockwise
  zIndex: 2e9, // The z-index (defaults to 2000000000)
  className: 'spinner', // The CSS class to assign to the spinner
  top: '50%', // Top position relative to parent
  left: '50%', // Left position relative to parent
  shadow: '0 0 1px transparent', // Box-shadow for the lines
  position: 'absolute' // Element positioning
};

 startSpinner(spinarea : string) {

  console.log('starting spinner in ');
  console.log(spinarea);

  var target = document.getElementById(spinarea);
  var spinner = new Spinner(this.opts).spin();
  target.appendChild(spinner.el);
  return spinner;
}



ngOnDestroy(){    

    if(this.initFormSubscription != undefined) this.initFormSubscription.unsubscribe();
    if(this.paymtSearchSubscription != undefined) this.paymtSearchSubscription.unsubscribe();
}
  

}
