import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentSearchService } from 'src/app/venpay/paymentsearch/PaymentSearchService.service';
import { ExcelService } from 'src/app/services/excel.service';
import { ChartType, Row,GoogleChartComponent } from "angular-google-charts";
import { formatDate } from '@angular/common';
//import { PopuphelpComponent } from 'src/app/venpay/popuphelp/popuphelp.component';
import { PopupcolheadersComponent } from '../popupcolheaders/popupcolheaders.component';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-searchresult',
  templateUrl: './searchresult.component.html',
  styleUrls: ['./searchresult.component.css']
})
export class SearchresultComponent implements OnInit {

 
  constructor(private paymentSearchService : PaymentSearchService,
              private excelService : ExcelService,
              private router : Router,
              public dialog: MatDialog
    ) { }

  isRecLimitReached : boolean ;
  MAX_LIMIT : number = 5000;
  isNoResultFound = false;
  
  tableData = new Array(); 
  chartParams : any = {};

  chartColumns = ['Taxpayer ID',	'AZ Agency ID',	'AZ Agency Name',	'Mail Code',	'City',	'State',	'Zip',	'Invoice #',	'Invoice Description',	'Invoice Date',	'Transaction Amount',	'Payment Amount',	'Payment Type',	'Payment #',	'Issue Date',	'Payment Status',	'Status Date',	'1099-MISC Box #'];
  
  ngOnInit() {

      this.isNoResultFound = false;
      this.isRecLimitReached = false;  

      this.tableData = this.paymentSearchService.searchResult;

      this.checkAndSetWarningMessages();

    
      var size = Object.keys(this.tableData).length;
       
      this.chartParams = {
        type: ChartType.Table,
        data: [],

        chartColumns: this.chartColumns
    }
      

     
      for(var i=0; i < size; i++) {

        var row =[
              this.tableData[i].vendorTaxId,
              this.tableData[i].department,
              this.tableData[i].venpayAgencyAfis.agencyName,  
              this.tableData[i].mailCode,
              this.tableData[i].city,
              this.tableData[i].state,
              this.tableData[i].zipCode,
              this.tableData[i].invoiceNo,
              this.tableData[i].invoiceDescription,
              this.tableData[i].invoiceDate,
              this.tableData[i].transactionAmount,
              this.tableData[i].paymentAmount,
              this.tableData[i].paymentType,
              this.tableData[i].warrantNo,
              this.tableData[i].issueDate,
              this.tableData[i].paymentStatus,
              this.tableData[i].paymentStatusDate,
              this.tableData[i].taxReptId
        ] ;  
  
        this.chartParams.data.push(row);
      }

  }



  checkAndSetWarningMessages() {

    if(this.tableData.length >= this.MAX_LIMIT) {
        this.isRecLimitReached = true;
    }
    if(this.tableData == undefined || this.tableData.length == 0 ) {
        this.isNoResultFound = true;
    }

  }

  getLastUpdateDate() {
    return formatDate(new Date(), 'MM/dd/yyyy', 'en'); 
  }

  exportToExcel() {

    var arrayToExport = this.chartParams.data.slice(); //clone

    arrayToExport.splice(0, 0, this.chartColumns);
    arrayToExport.splice(1, 0, []); //insert blank row

    this.excelService.exportAsExcel(arrayToExport);
  }


  print() {

     this.printDiv();
  }
  


  returnToSearch() {

    this.router.navigate(['paymtsearch']);
    return false;
  }

  onChartReady(chart: GoogleChartComponent) {
    console.log('chart is ready');
    console.log(chart);
    chart.chartWrapper.getChart();
  }

   //Text for help modals and tooltips

  
 helpTexts: {[key: string]: string} =
   {
    "mainHelp" : 
    "<a href='javascript:void(0)' (mouseover)='showHelpDetail(\'TaxpayerId\')'> TaxpayerId </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\">AzAgencyId </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\">AzAgencyName </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\">MailCode </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\"> City </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\"> State </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\"> Zip </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\">InvoiceNo </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\">InvoiceDesc </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\">InvoiceDate </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\">TransactionAmt </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\">PaymentAmt </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\">PaymentType </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\">PaymentNo </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\"> IssueDate </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\"> PaymentStatus </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\"> StatusDate </a><br>\
      <a href='javascript:void(0)' (mouseover)=\"showHelpDetail('')\"> 1099MiscBoxNo </a><br>",

   }
  
  showHelpModal(name : string) {

    //PopupcolheadersComponent
    //PopuphelpComponent
    const dialogRef = this.dialog.open(PopupcolheadersComponent, {
      width: '300px',
      height: 'auto',
      data : this.helpTexts[name],
      //panelClass : 'help-class',
      backdropClass: 'backdropBackground' 
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
   
    });
  }


  closeModal(): void {
    //this.dialogRef.close();
  }


  printDiv() {

  
    var mywindow = window.open('', 'PRINT', 'height=800,width=800');

    mywindow.document.write('<html><head><title>' + 'Vendor Payment Search'  + '</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1>' + 'Payment Search Results'  + '</h1>');
    mywindow.document.write('<p>Last Updated: ' + '<b>' +this.getLastUpdateDate() +'</b>');
    mywindow.document.write('<p><p>');
    mywindow.document.write('<table class="gridView" cellspacing="2" cellpadding="2" rules="all" border="0" id="gridViewResults" style="border-width:0px;">');
    mywindow.document.write(document.getElementsByClassName('google-visualization-table-table')[0].innerHTML);
    mywindow.document.write('</table>');
    mywindow.document.write('</body></html>');
    mywindow.document.close();

    mywindow.focus();
    mywindow.print();
    mywindow.close();
    return true;
  }


}

