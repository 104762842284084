Click to see description <p>
<a href='javascript:void(0)' (click)="showHelpDetail('TaxpayerId')"> TaxpayerId </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('AzAgencyId')">AzAgencyId </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('AzAgencyName')">AzAgencyName </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('MailCode')">MailCode </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('City')"> City </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('State')"> State </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('Zip')"> Zip </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('InvoiceNo')">InvoiceNo </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('InvoiceDesc')">InvoiceDesc </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('InvoiceDate')">InvoiceDate </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('TransactionAmt')">TransactionAmt </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('PaymentAmt')">PaymentAmt </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('PaymentType')">PaymentType </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('PaymentNo')">PaymentNo </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('IssueDate')"> IssueDate </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('PaymentStatus')"> PaymentStatus </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('StatusDate')"> StatusDate </a><br>
<a href='javascript:void(0)' (click)="showHelpDetail('1099MiscBoxNo')"> 1099MiscBoxNo </a><br>

<p>
<div mat-dialog-actions>
  <button mat-raised-button (click)="closeModal()">Close</button>
</div>
