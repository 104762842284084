import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { HttpHeaders } from '@angular/common/http';

import { environment } from "../../../environments/environment";
import { SearchParams } from 'src/app/venpay/paymentsearch/models/searchparams.model';




@Injectable({
  providedIn: 'root'
})
export class PaymentSearchService {


    constructor(private http: HttpClient) { }

    savedSearchParams : SearchParams = new SearchParams ;
    searchResult : any

    public searchPaymt(searchParams : SearchParams) : Observable<any> {

          console.log('In service method');
          const searchPaymtEndpoint = environment.server_url  + 'searchpayment' ;
          const bodyString = JSON.stringify(searchParams); 
          const headers = new Headers({ 'Content-type': 'application/json' });
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

          return this.http.post(searchPaymtEndpoint, bodyString, httpOptions);
    }




    public getAllAgencies() : Observable<any> {

          const setAppTypeEndpoint = environment.server_url  + 'agencies' ;
          //const bodyString = JSON.stringify({"appType":appType});
          //console.log(bodyString);
          const headers = new Headers({ 'Content-type': 'application/json' });
          const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

        //return this.http.post(setAppTypeEndpoint,, httpOptions);
          return this.http.get(setAppTypeEndpoint, httpOptions);
    }


}




